import { Injectable } from '@angular/core';
import { APFASAuction, APFASAuctionBids } from './tso-auctions';
import { catchError, map, Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TsoAuctionsService {
  endpoint = '/api/v1/reserve-auctions';

  constructor(private httpClient: HttpClient) {}

  getAuctions(): Observable<APFASAuction[]> {
    return this.httpClient.get<APFASAuction[]>(`${this.endpoint}`);
  }

  getBids(auctionId: string, resourceId: string): Observable<APFASAuctionBids> {
    return this.httpClient
      .get<APFASAuctionBids[]>(`${this.endpoint}/${auctionId}/bids`, {
        params: new HttpParams({
          fromObject: {
            resourceId
          }
        })
      })
      .pipe(
        map((result) => {
          if (!result || !result.length) {
            return null;
          }
          return result[0];
        })
      );
  }

  saveBids(bids: Partial<APFASAuctionBids>): Observable<APFASAuctionBids> {
    return this.httpClient.put<APFASAuctionBids>(`${this.endpoint}/${bids.timeSeriesId}/bids`, bids);
  }
}
