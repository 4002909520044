import { APFASAuction, APFASAuctionBid, APFASAuctionBids } from '../../app/shared/tso-auctions/tso-auctions';

export class TsoAuctionsActivate {
  public static readonly type = '[TSO Auctions] Route activated';
}

export class TsoAuctionsDeactivate {
  public static readonly type = '[TSO Auctions] Route deactivated';
}

export class TsoAuctionsUpdateAuctionsCommand {
  public static readonly type = '[TSO Auctions] Update auctions';
}

export class TsoAuctionsUpdatedEvent {
  public static readonly type = '[TSO Auctions] Auctions updated';

  constructor(public data: APFASAuction[]) {}
}

export class TsoAuctionsUpdateBidsCommand {
  public static readonly type = '[TSO Auctions] Select a given auction';

  constructor(public auctionId?: string, public resourceId?: string) {}
}

export class TsoAuctionsBidsUpdatedEvent {
  public static readonly type = '[TSO Auctions] Bids updated for auction';

  constructor(public bids: APFASAuctionBids) {}
}

/**
 * Update current auction with the current state at the time of handling.
 * This is emitted when the auctions list has changed, or the bids object is loaded
 */
export class TsoAuctionsUpdateCurrentAuctionCommand {
  public static readonly type = '[TSO Auctions] Update current auction';
}

export class TsoAuctionsSaveBidsCommand {
  public static readonly type = '[TSO Auctions] Save current bids';
}

export class TsoAuctionsUpdateBidsListCommand {
  public static readonly type = '[TSO Auctions] Update draft bids list';

  constructor(public bids: APFASAuctionBid[]) {}
}
